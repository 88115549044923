export const VuexTypes = Object.freeze({
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  USER_REQUEST: 'USER_REQUEST',
  AUTH_ERROR: 'AUTH_ERROR',
  CHECK_AUTH_INFO: 'CHECK_AUTH_INFO',

  USERS_LIST: 'USERS_LIST',
  USERS_ADD: 'USERS_ADD',
  USERS_LOAD: 'USERS_LOAD',
  USERS_DELETE: 'USERS_DELETE',
  USERS_UPDATE: 'USERS_UPDATE',

  ROLES_LIST: 'ROLES_LIST',
  ROLES_ADD: 'ROLES_ADD',
  ROLES_LOAD: 'ROLES_LOAD',
  ROLES_UPDATE: 'ROLES_UPDATE',
  ROLES_DELETE: 'ROLES_DELETE',

  TRAYS_LIST: 'TRAYS_LIST',
  TRAYS_ADD: 'TRAYS_ADD',
  TRAYS_LOAD: 'TRAYS_LOAD',
  TRAYS_DELETE: 'TRAYS_DELETE',
  TRAYS_UPDATE: 'TRAYS_UPDATE',

  MODEL_TRAINING_LIST: 'MODEL_TRAINING_LIST',
  MODEL_TRAINING_ADD: 'MODEL_TRAINING_ADD',
  MODEL_TRAINING_LOAD: 'MODEL_TRAINING_LOAD',
  MODEL_TRAINING_DELETE: 'MODEL_TRAINING_DELETE',
  MODEL_TRAINING_UPDATE: 'MODEL_TRAINING_UPDATE',

  ORDERS_LIST: 'ORDERS_LIST',
  ORDERS_ADD: 'ORDERS_ADD',
  ORDERS_LOAD: 'ORDERS_LOAD',
  ORDERS_DELETE: 'ORDERS_DELETE',
  ORDERS_UPDATE: 'ORDERS_UPDATE',

  THEMES_LIST: 'THEMES_LIST',
  THEMES_ADD: 'THEMES_ADD',
  THEMES_LOAD: 'THEMES_LOAD',
  THEMES_DELETE: 'THEMES_DELETE',
  THEMES_UPDATE: 'THEMES_UPDATE',

  CATEGORIES_LIST: 'CATEGORIES_LIST',
  CATEGORIES_ADD: 'CATEGORIES_ADD',
  CATEGORIES_LOAD: 'CATEGORIES_LOAD',
  CATEGORIES_DELETE: 'CATEGORIES_DELETE',
  CATEGORIES_UPDATE: 'CATEGORIES_UPDATE',

  PRODUCT_TYPES_LIST: 'PRODUCT_TYPES_LIST',
  PRODUCT_TYPES_ADD: 'PRODUCT_TYPES_ADD',
  PRODUCT_TYPES_LOAD: 'PRODUCT_TYPES_LOAD',
  PRODUCT_TYPES_DELETE: 'PRODUCT_TYPES_DELETE',
  PRODUCT_TYPES_UPDATE: 'PRODUCT_TYPES_UPDATE',
  PRODUCT_TYPES_ALL: 'PRODUCT_TYPES_ALL',

  HUMAN_PRODUCTS_LIST: 'HUMAN_PRODUCTS_LIST',
  HUMAN_PRODUCTS_ADD: 'HUMAN_PRODUCTS_ADD',
  HUMAN_PRODUCTS_LOAD: 'HUMAN_PRODUCTS_LOAD',
  HUMAN_PRODUCTS_DELETE: 'HUMAN_PRODUCTS_DELETE',
  HUMAN_PRODUCTS_UPDATE: 'HUMAN_PRODUCTS_UPDATE',
  HUMAN_PRODUCTS_LIST_ALL: 'HUMAN_PRODUCTS_LIST_ALL',

  COMBOS_LIST: 'COMBOS_LIST',
  COMBOS_ADD: 'COMBOS_ADD',
  COMBOS_LOAD: 'COMBOS_LOAD',
  COMBOS_DELETE: 'COMBOS_DELETE',
  COMBOS_UPDATE: 'COMBOS_UPDATE',

  MODEL_CLASSES_LIST: 'MODEL_CLASSES_LIST',
  MODEL_CLASSES_ADD: 'MODEL_CLASSES_ADD',
  MODEL_CLASSES_LOAD: 'MODEL_CLASSES_LOAD',
  MODEL_CLASSES_DELETE: 'MODEL_CLASSES_DELETE',
  MODEL_CLASSES_UPDATE: 'MODEL_CLASSES_UPDATE',

  SEGMENTED_IMAGES_LIST: 'SEGMENTED_IMAGES_LIST',
  SEGMENTED_IMAGES_SHOW: 'SEGMENTED_IMAGES_SHOW',
  SEGMENTED_IMAGES_ADD: 'SEGMENTED_IMAGES_ADD',
  SEGMENTED_IMAGES_LOAD: 'SEGMENTED_IMAGES_LOAD',
  SEGMENTED_IMAGES_DELETE: 'SEGMENTED_IMAGES_DELETE',
  SEGMENTED_IMAGES_UPDATE: 'SEGMENTED_IMAGES_UPDATE',

  MODELS_GET: 'MODELS_GET',
  MODELS_LIST: 'MODELS_LIST',
  MODELS_ADD: 'MODELS_ADD',
  MODELS_LOAD: 'MODELS_LOAD',
  MODELS_DELETE: 'MODELS_DELETE',
  MODELS_UPDATE: 'MODELS_UPDATE',

  TRAY_PRODUCTS_LIST: 'TRAY_PRODUCTS_LIST',
  TRAY_PRODUCTS_ADD: 'TRAY_PRODUCTS_ADD',
  TRAY_PRODUCTS_LOAD: 'TRAY_PRODUCTS_LOAD',
  TRAY_PRODUCTS_DELETE: 'TRAY_PRODUCTS_DELETE',
  TRAY_PRODUCTS_UPDATE: 'TRAY_PRODUCTS_UPDATE',

  RESTAURANT_LIST: 'RESTAURANT_LIST',
  RESTAURANT_ADD: 'RESTAURANT_ADD',
  RESTAURANT_LOAD: 'RESTAURANT_LOAD',
  RESTAURANT_DELETE: 'RESTAURANT_DELETE',
  RESTAURANT_UPDATE: 'RESTAURANT_UPDATE',

  RASPBERRY_LIST: 'RASPBERRY_LIST',
  RASPBERRY_ADD: 'RASPBERRY_ADD',
  RASPBERRY_LOAD: 'RASPBERRY_LOAD',
  RASPBERRY_DELETE: 'RASPBERRY_DELETE',
  RASPBERRY_UPDATE: 'RASPBERRY_UPDATE',

  MODEL_CHECKPOINT_LIST: 'MODEL_CHECKPOINT_LIST',
  MODEL_CHECKPOINT_ADD: 'MODEL_CHECKPOINT_ADD',
  MODEL_CHECKPOINT_LOAD: 'MODEL_CHECKPOINT_LOAD',
  MODEL_CHECKPOINT_DELETE: 'MODEL_CHECKPOINT_DELETE',
  MODEL_CHECKPOINT_UPDATE: 'MODEL_CHECKPOINT_UPDATE',

  SEGMENTATION_MODEL_LIST: 'SEGMENTATION_MODEL_LIST',
  SEGMENTATION_MODEL_ADD: 'SEGMENTATION_MODEL_ADD',
  SEGMENTATION_MODEL_LOAD: 'SEGMENTATION_MODEL_LOAD',
  SEGMENTATION_MODEL_DELETE: 'SEGMENTATION_MODEL_DELETE',
  SEGMENTATION_MODEL_UPDATE: 'SEGMENTATION_MODEL_UPDATE',

  SEGMENTATION_MODEL_CHECKPOINT_LIST: 'SEGMENTATION_MODEL_CHECKPOINT_LIST',
  SEGMENTATION_MODEL_CHECKPOINT_ADD: 'SEGMENTATION_MODEL_CHECKPOINT_ADD',
  SEGMENTATION_MODEL_CHECKPOINT_LOAD: 'SEGMENTATION_MODEL_CHECKPOINT_LOAD',
  SEGMENTATION_MODEL_CHECKPOINT_DELETE: 'SEGMENTATION_MODEL_CHECKPOINT_DELETE',
  SEGMENTATION_MODEL_CHECKPOINT_UPDATE: 'SEGMENTATION_MODEL_CHECKPOINT_UPDATE',

  CASH_DESK_LIST: 'CASH_DESK_LIST',
  CASH_DESK_ADD: 'CASH_DESK_ADD',
  CASH_DESK_LOAD: 'CASH_DESK_LOAD',
  CASH_DESK_DELETE: 'CASH_DESK_DELETE',
  CASH_DESK_UPDATE: 'CASH_DESK_UPDATE',
  CASH_DESK_SET_THEME: 'CASH_DESK_SET_THEME',

  CASH_DESK_PRODUCT_LIST_LIST: 'CASH_DESK_PRODUCT_LIST_LIST',
  CASH_DESK_PRODUCT_LIST_ADD: 'CASH_DESK_PRODUCT_LIST_ADD',
  CASH_DESK_PRODUCT_LIST_LOAD: 'CASH_DESK_PRODUCT_LIST_LOAD',
  CASH_DESK_PRODUCT_LIST_DELETE: 'CASH_DESK_PRODUCT_LIST_DELETE',
  CASH_DESK_PRODUCT_LIST_UPDATE: 'CASH_DESK_PRODUCT_LIST_UPDATE',

  LIST_PERMISSIONS: 'LIST_PERMISSIONS',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  REGISTER_USER: 'REGISTER_USER',

  CASHIER_LOAD: 'CASHIER_LOAD',
  CASHIER_STATE: 'CASHIER_STATE',
  CASHIER_SET_RESTAURANT: 'CASHIER_SET_RESTAURANT',
  CASHIER_SET_RASPBERRY: 'CASHIER_SET_RASPBERRY',

  CLASS_GROUPS_LIST: 'CLASS_GROUPS_LIST',
  CLASS_GROUPS_ADD: 'CLASS_GROUPS_ADD',
  CLASS_GROUPS_CLONE: 'CLASS_GROUPS_CLONE',
  CLASS_GROUPS_LOAD: 'CLASS_GROUPS_LOAD',
  CLASS_GROUPS_DELETE: 'CLASS_GROUPS_DELETE',
  CLASS_GROUPS_UPDATE: 'CLASS_GROUPS_UPDATE',

  CLASS_GROUP_ITEMS_LIST: 'CLASS_GROUP_ITEMS_LIST',
  CLASS_GROUP_ITEMS_ADD: 'CLASS_GROUP_ITEMS_ADD',
  CLASS_GROUP_ITEMS_LOAD: 'CLASS_GROUP_ITEMS_LOAD',
  CLASS_GROUP_ITEMS_DELETE: 'CLASS_GROUP_ITEMS_DELETE',
  CLASS_GROUP_ITEMS_UPDATE: 'CLASS_GROUP_ITEMS_UPDATE',

  LANGUAGES_LIST: 'LANGUAGES_LIST',
  LANGUAGES_CLASSIFICATOR: 'LANGUAGES_CLASSIFICATOR',
  LANGUAGE_UPDATE_DATA: 'LANGUAGE_UPDATE_DATA',
  LANGUAGE_UPDATE: 'LANGUAGE_UPDATE',
  LANGUAGE_CREATE: 'LANGUAGE_CREATE',
  LANGUAGE_CREATE_DATA: 'LANGUAGE_CREATE_DATA',
  LANGUAGE_DELETE: 'LANGUAGE_DELETE',

  TRANSLATIONS_LIST: 'TRANSLATIONS_LIST',
  UNTRANSLATED: 'UNTRANSLATED',
  TRANSLATION_UPDATE_DATA: 'TRANSLATION_UPDATE_DATA',
  TRANSLATION_UPDATE: 'TRANSLATION_UPDATE',
  TRANSLATION_CREATE: 'TRANSLATION_CREATE',
  TRANSLATION_CREATE_DATA: 'TRANSLATION_CREATE_DATA',
  TRANSLATION_DELETE: 'TRANSLATION_DELETE',

  /* Types only for cashier view */
  CASHIER_LOAD_RASPBERRIES: 'CASHIER_LOAD_RASPBERRIES',
  CASHIER_LOAD_RASPBERRIES_IN_PROGRESS: 'CASHIER_LOAD_RASPBERRIES_IN_PROGRESS',
  CASHIER_SET_RASPBERRIES: 'CASHIER_SET_RASPBERRIES',
  CASHIER_FETCH_CURRENT_ORDERS: 'CASHIER_FETCH_CURRENT_ORDERS',
  CASHIER_SET_ORDERS: 'CASHIER_SET_ORDERS',
  CASHIER_ADD_TRAY: 'CASHIER_ADD_TRAY',
  CASHIER_UPDATE_TRAY: 'CASHIER_UPDATE_TRAY',
  CASHIER_SET_TRAY: 'CASHIER_SET_TRAY',

  CASHIER_SET_CURRENT_RASPBERRY: 'CASHIER_SET_CURRENT_RASPBERRY',

  CASHIER_TRAYS_ACTION_LOAD_IMAGE: 'CASHIER_TRAYS_ACTION_LOAD_IMAGE',
  CASHIER_TRAYS_MUTATION_SET_IMAGE: 'CASHIER_TRAYS_MUTATION_SET_IMAGE',
  CASHIER_ORDERS_MUTATION_SET_LOADING: 'CASHIER_ORDERS_MUTATION_SET_LOADING',
  CASHIER_TRAYS_ACTION_FETCH_TRAYS: 'CASHIER_TRAYS_ACTION_FETCH_TRAYS',
  CASHIER_TRAYS_MUTATION_SET_LOADING: 'CASHIER_TRAYS_MUTATION_SET_LOADING',

  CASHIER_ORDERS_MUTATION_UPDATE: 'CASHIER_ORDERS_MUTATION_UPDATE',
  CASHIER_ORDERS_ACTION_UPDATE: 'CASHIER_ORDERS_ACTION_UPDATE',
  CASHIER_ORDERS_ACTION_LOCK: 'CASHIER_ORDERS_ACTION_LOCK',
  CASHIER_ORDERS_ACTION_UNLOCK: 'CASHIER_ORDERS_ACTION_UNLOCK',
  CASHIER_ORDERS_ACTION_SET_ORDER_LOADING: 'CASHIER_ORDERS_ACTION_SET_ORDER_LOADING',
  CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING: 'CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING',
  CASHIER_ORDERS_MUTATION_SET_WRONG_CODES: 'CASHIER_ORDERS_MUTATION_SET_WRONG_CODES',
  CASHIER_ORDERS_ACTION_VERIFY_AGE: 'CASHIER_ORDERS_ACTION_VERIFY_AGE',
  CASHIER_ORDERS_ACTION_EXCEPTION: 'CASHIER_ORDERS_ACTION_EXCEPTION',

  CASHIER_LOAD_CASH_DESKS: 'CASHIER_LOAD_CASH_DESKS',
  CASHIER_LOAD_CASH_DESKS_IN_PROGRESS: 'CASHIER_LOAD_RASPBERRIES_IN_PROGRESS',
  CASHIER_SET_CASH_DESKS: 'CASHIER_SET_CASH_DESKS',

  CASHIER_CATEGORIES_ACTION_SET: 'CASHIER_CATEGORIES_ACTION_SET',
  CASHIER_CATEGORIES_MUTATIONS_SET: 'CASHIER_CATEGORIES_MUTATIONS_SET',

  CASHIER_HUMAN_PRODUCTS_ACTION_SET: 'CASHIER_HUMAN_PRODUCTS_ACTION_SET',
  CASHIER_HUMAN_PRODUCTS_MUTATION_SET: 'CASHIER_HUMAN_PRODUCTS_MUTATION_SET',

  CASHIER_TRAYS_ACTION_PRODUCT_PLUS: 'CASHIER_TRAYS_ACTION_PRODUCT_PLUS',
  CASHIER_TRAYS_ACTION_PRODUCT_MINUS: 'CASHIER_TRAYS_ACTION_PRODUCT_MINUS',
  CASHIER_TRAYS_ACTION_SET_TRAY_PRODUCT: 'CASHIER_TRAYS_ACTION_SET_TRAY_PRODUCT',
  CASHIER_TRAYS_MUTATION_SET_TRAY_PRODUCT: 'CASHIER_TRAYS_MUTATION_SET_TRAY_PRODUCT',
  CASHIER_TRAYS_ACTION_DELETE_TRAY: 'CASHIER_TRAYS_ACTION_DELETE_TRAY',
  CASHIER_TRAYS_MUTATION_DELETE_TRAY: 'CASHIER_TRAYS_MUTATION_DELETE_TRAY',
  CASHIER_TRAYS_ACTION_SET_TRAY_LOADING: 'CASHIER_TRAYS_ACTION_SET_TRAY_LOADING',
  CASHIER_TRAYS_MUTATION_SET_TRAY_LOADING: 'CASHIER_TRAYS_MUTATION_SET_TRAY_LOADING',
});
