<template>
  <div class="row">
    <div
      v-if="showCategories"
      class="col-4"
    >
      <div class="category-list">
        <template v-for="(category) in categories">
          <div
            v-if="humanProducts[category.id]"
            :key="category.id"
            :class="[{ active: selectedCategory?.id === category.id }, 'category', 'container', 'pl-1', 'my-2']"
            @click="selectedCategory = category"
          >
            <img
              v-if="category.icon_base64"
              :src="category.icon_base64"
              class="my-1 mx-1 category-icon"
              alt="Category icon"
            >

            <img
              v-else
              src="@/static/img/category_placeholder.svg"
              class="my-1 mx-1 category-icon"
              alt="Category icon"
            >

            <div class="category-name d-flex align-items-center">
              {{ getCategoryName(category) }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="showCategories"
      class="col-8"
    >
      <div
        v-if="selectedCategory"
        class="row"
      >
        <div
          v-for="product in humanProducts[selectedCategory.id]"
          :key="product.id"
          class="col-6 human-product"
          @click="selectProduct(product)"
        >
          <ProductSelectComponent :product="product" />
        </div>
      </div>
    </div>

    <div
      v-else
      class="col-12"
    >
      <div class="row">
        <div
          v-for="product in selectedProducts"
          :key="product.id"
          class="col-4 human-product"
          @click="selectProduct(product)"
        >
          <ProductSelectComponent :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSelectComponent from '@/components/ProductSelectComponent/ProductSelectComponent.vue';
import { getCategoryName } from '@/helpers/tray';

export default {
  name: 'ProductSelect',

  components: {
    ProductSelectComponent,
  },

  props: {
    value: {
      type: Object,
    },
    categories: {
      type: Array,
      required: true,
    },
    humanProducts: {
      type: Object,
      required: true,
    },
    showCategories: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedCategory: null,
      selectedProduct: null,
      getCategoryName,
    };
  },

  computed: {
    selectedProducts() {
      let selected_products = [];
      for (const { id } of this.categories) {
        if (id in this.humanProducts) {
          for (const value of this.humanProducts[id]) {
            selected_products.push(value);
          }
        }
      }
      return selected_products ? selected_products : this.humanProducts;
    },
  },

  watch: {
    selectedProduct(newProduct) {
      this.$emit('input', newProduct);
    },
  },

  methods: {
    selectProduct(humanProduct) {
      this.selectedProduct = humanProduct;
      this.$emit('productClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.category-list {
  font-family: "Avenir";
  font-weight: 800;
  font-size: 14px;
  text-align: left;

  .category {
    height: 44px;
    width: auto;
    border-radius: 10px;

    &.active {
      background: #ffc381;
      filter: invert(1);
    }

    filter: invert(0);
  }

  .category-icon {
    padding-top: 4px;
    width: 25px;
    height: 25px;
  }
}

.category-name {
  line-height: 1.1;
  color: black;
}

.container {
  display: flex;
}
</style>
