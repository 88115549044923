<template>
  <div>
    <hr>

    <div
      v-if="!currentTrayId"
    >
      No tray ID to add product to
    </div>

    <div v-if="currentTrayId">
      Tray ID: {{ currentTrayId }}
    </div>

    <b-form-group :label="'Product type'">
      <b-form-select
        v-model="toPost.product_type_id"
        :disabled="!currentTrayId"
        :options="productTypesOptions"
      />
    </b-form-group>

    <b-form-group :label="'Segmentation data'">
      <b-form-input
        v-model="toPost.segmentation_data"
        :disabled="!currentTrayId"
        :options="productTypesOptions"
      />
    </b-form-group>

    <b-button
      :disabled="!currentTrayId"
      @click.prevent="addProduct"
    >
      Add product
    </b-button>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import ProductTypesService from '@/services/product-types-service';

export default {
  name: 'EmulatorAddProduct',

  components: {
  },

  props: ['currentTrayId', 'cdid', 'token'],

  data() {
    return {
      toPost: {
        segmentation_data: '{}',
        product_type_id: null,
      },
      productTypesOptions: [],
      human_products_options: [],
    };
  },

  computed: {
    ...mapState(['auth']),
  },

  watch: {
    CDID: function () {
      this.fetchProductTypes();
    },
    currentTrayId: function () {
      this.fetchProductTypes();
    },
  },

  methods: {
    addProduct() {
      axios.post(
        '/api/detected_product',
        {
          tray_id: this.currentTrayId,
          ...this.toPost,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );
    },
    fetchProductTypes() {
      ProductTypesService.listAll().then(({ data }) => {
        this.productTypesOptions = data.map((item) => {
          return {
            text: `${item.name} (Code: ${item?.product?.code})`,
            value: item.id,
          };
        });
      });
    },
  },
};
</script>
