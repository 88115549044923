import { VuexTypes } from '../../types';
import { CASH_DESK_STATES } from '@/constants';
import OrdersService from '@/services/orders-service';
import Vue from 'vue';

const state = {
  byRaspberry: {},
  loading: false,
  mapIds: {},
  wrongProductCodes: [],
};

const getters = {
  byId: (state) => (id) => {
    return state.byRaspberry[state.mapIds[id]];
  },
  getWrongProductCodes: ({ wrongProductCodes }) => wrongProductCodes,
};

const mutations = {
  [VuexTypes.CASHIER_SET_ORDERS]: (state, orders) => {
    console.log('CASHIER_SET_ORDERS', orders);
    orders.forEach(order => {
      order._loading = false;
      Vue.set(state.byRaspberry, order.raspberry_id, order);
      Vue.set(state.mapIds, order.id, order.raspberry_id);
    });
  },
  [VuexTypes.CASHIER_ORDERS_MUTATION_UPDATE]: (state, orderData) => {
    Vue.set(state.byRaspberry, orderData.raspberry_id, { ...state.byRaspberry[orderData.raspberry_id], ...orderData });
  },
  [VuexTypes.CASHIER_ORDERS_MUTATION_SET_LOADING]: (state, value) => {
    state.loading = value ? true : false;
  },
  [VuexTypes.CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING]: (state, { order, loading }) => {
    state.byRaspberry[order.raspberry_id]._loading = loading;
  },
  [VuexTypes.CASHIER_ORDERS_MUTATION_SET_WRONG_CODES]: (state, codes) => {
    state.wrongProductCodes = codes;
  },
};

const actions = {
  [VuexTypes.CASHIER_FETCH_CURRENT_ORDERS]: ({ commit, rootState }) => {
    commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_LOADING, true);
    OrdersService.getCurrent(rootState.cashierRaspberries.list.map(a => a.id))
      .then(resp => {
        commit(VuexTypes.CASHIER_SET_ORDERS, resp.data);
        commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_LOADING, false);
      });
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_UPDATE]: ({ commit }, orderData) => {
    commit(VuexTypes.CASHIER_ORDERS_MUTATION_UPDATE, orderData);
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_LOCK]: ({ commit }, order) => {
    changeStateWithAction(commit, order, CASH_DESK_STATES.LOCKED);
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_UNLOCK]: ({ commit }, order) => {
    changeStateWithAction(commit, order, CASH_DESK_STATES.IN_PROGRESS);
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_SET_ORDER_LOADING]: ({ commit }, { order, loading }) => {
    commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING, { order, loading });
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_VERIFY_AGE]: ({ commit }, order) => {
    commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_LOADING, true);
    OrdersService.verifyAge(order.raspberry_id)
      .then(resp => {
        // commit(VuexTypes.CASHIER_ORDERS_MUTATION_UPDATE, resp.data); // both variants work
        commit(VuexTypes.CASHIER_ORDERS_MUTATION_UPDATE, { raspberry_id: order.raspberry_id, age_verified: resp.data.age_verified });
        commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_LOADING, false);
      });
  },
  [VuexTypes.CASHIER_ORDERS_ACTION_EXCEPTION]: ({ commit }, exception) => {
    const codes = exception?.request.order.dish.map(({ id }) => id) || [];
    commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_WRONG_CODES, codes);
  },
};

function changeStateWithAction(commit, order, state) {
  if (order._loading) {
    console.error('changeStateWithAction Dont change state while order loading', { order, state });
    return;
  }

  commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING, { order, loading: true });

  OrdersService.changeState(order.id, state)
    .then(resp => {
      commit(VuexTypes.CASHIER_ORDERS_MUTATION_UPDATE, resp.data);
    }).catch(err => {
      console.log('changeStateWithAction fail', err.message, order, state);
    }).finally(() => {
      commit(VuexTypes.CASHIER_ORDERS_MUTATION_SET_ORDER_LOADING, { order, loading: false });
    });
}

export default {
  state,
  mutations,
  actions,
  getters,
};
