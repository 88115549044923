<template>
  <b-collapse
    :id="id"
    class="collapse-content"
    visible
  >
    <div class="img-container">
      <div class="tray-img-container">
        <img
          :id="tray_img_id"
          :src="tray.loadedImage"
          @load="onTrayLoad"
        >

        <div v-if="ImgLoaded">
          <TrayImageLabels
            v-for="(product, i) in tray_products_to_display"
            :key="i"
            :x-coord="addLabelcoords(product, 'x', i)"
            :y-coord="addLabelcoords(product, 'y', i)"
            :label-text="`${i+1}. ${getProductName(product)}`"
            :labelw="label_w"
            :labelh="label_h"
            :invalid="(!getCorrectedProductCode(product) && wrongProductCodes.includes(haveProductCode(product)))
              || wrongProductCodes.includes(getCorrectedProductCode(product))
              || product.order_product?.cash_desk_price === undefined"
          />
        </div>
      </div>
    </div>

    <div
      v-if="tray.tray_products"
      class="product-list"
    >
      <tray-products-table
        :tray="tray"
        :disabled="!canEditProducts"
        @editHumanProduct="$emit('editHumanProduct', $event)"
      />
    </div>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import TrayProductsTable from './TrayProductsTable.vue';
import { canEditOrderProducts } from '@/rules/order-rules';
import TrayImageLabels from './TrayImageLabels.vue';
import { getHumanProduct } from '@/helpers/tray';

export default {
  name: 'TrayContent',

  components: {
    TrayProductsTable,
    TrayImageLabels,
  },

  props: {
    tray: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tray_img_id: 'tray-1',
      tray_counter: 1,
      w_orig: null,
      h_orig: null,
      w_img: null,
      h_img: null,
      label_w: 170,
      label_h: 55,
      ImgLoaded: false,
    };
  },

  computed: {
    ...mapGetters('cashierOrders', {
      getOrderById: 'byId',
      wrongProductCodes: 'getWrongProductCodes',
    }),
    canEditProducts() {
      return canEditOrderProducts(this.order);
    },
    tray_products_to_display(){
      return this.tray.tray_products ? this.tray.tray_products.filter(({ segmented_image }) => segmented_image) : [];
    },
  },

  watch: {
    tray: {
      immediate: true,
      handler(tray) {
        if (!tray.loadedImage) { // TODO: tray.loadedImage vienmēr undefined pat ja jau iestatīts
          this.loadTrayImage(tray); // TODO: load labels when new tray added, currently labels only load on page load 
        }
      },
    },
  },

  methods: {
    ...mapActions('cashierTrays', {
      loadTrayImage: VuexTypes.CASHIER_TRAYS_ACTION_LOAD_IMAGE,
    }),
    addLabelcoords({ segmented_image }, coord, index) {
      const { data = {} } = segmented_image || {};
      const { rect = {} } = data || {};
      const { x = index * 200 + 15, y = index * 200 + 15, width = 224, height = 74 } = rect || {};
      if (coord === 'x') {
        return ((x + width / 2) * this.w_img / this.w_orig) - (this.label_w / 2);
      }
      if (coord === 'y') {
        return ((y + height / 2) * this.h_img / this.h_orig) - (this.label_h / 2);
      }
    },
    onTrayLoad () {
      let myImg = document.querySelector(`#${this.tray_img_id}`);
      // Original img size
      this.w_orig = myImg.naturalWidth;
      this.h_orig = myImg.naturalHeight;
      // CSS resized img size
      this.w_img = myImg.clientWidth;
      this.h_img = myImg.clientHeight;

      this.tray_counter++;
      this.tray_img_id = `tray-${this.tray_counter}`;
      this.ImgLoaded = true;
    },
    humanProduct(trayProduct) {
      return getHumanProduct(trayProduct);
    },
    getProductName(product) {
      const item = this.humanProduct(product);
      return item?.name || 'Unknown Product';
    },
    haveProductCode(item) {
      const product = item?.human_product_corrected || item?.human_product || undefined;
      return product?.code || false;
    },
    getCorrectedProductCode(item) {
      const product = item?.human_product_corrected || undefined;
      return product?.code || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-content {
  overflow:hidden;

  &.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
}

.img-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.tray-img-container {
  position: relative;
  overflow: hidden;

  img {
    max-height: 32.625rem;
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
