<template>
  <div
    class="tray"
  >
    <tray-header
      :tray="tray"
      :tray-index="trayIndex"
      :collapse-id="contentId"
      :order="order"
    />

    <tray-content
      :id="contentId"
      :tray="tray"
      :order="order"
    />
  </div>
</template>

<script>
import TrayHeader from './TrayHeader.vue';
import TrayContent from './TrayContent.vue';

export default {
  name: 'Tray',

  components: {
    TrayHeader,
    TrayContent,
  },

  provide() {
    return {
      getTrayOrder: () => this.order,
    };
  },

  props: {
    tray: {
      type: Object,
      required: true,
    },
    trayIndex: {
      type: Number,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    contentId() {
      return 'tray-content-' + this.tray.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.tray {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;

  .tray-header {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
    }

    > div:nth-child(2) {
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      color: #777777;
    }
  }

  .toggler {
    &.collapsed {
      img {
        transform: rotate(90deg);
      }
    }

    img {
      -webkit-transition: 0.1s ease-in-out;
      -moz-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
    }
  }

  /* Animācijas var bremzēt kasierim */
  .collapse-content {
    &.collapsing {
      -webkit-transition: none;
      transition: none;
      display: none;
    }
  }
}

.tray-img {
  background: url('@/static/img/board.jpg') no-repeat;
  height: 521px;

  img {
    height: 100%;
  }
}
</style>
