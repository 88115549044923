<template>
  <div>
    <div
      class="label"
      :style="{ 'left': xCoord + 'px',
                'top': yCoord + 'px', 
                'width': labelw + 'px',
                'height': labelh + 'px',
                'background-color': color }"
    >
      <div class="label-inner">
        <p class="label-text">
          {{ labelText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrayImageLabels',

  props: {
    xCoord: {
      type: Number,
      required: true,
    },
    yCoord: {
      type: Number,
      required: true,
    },
    labelw: {
      type: Number,
      default: 224,
    },
    labelh: {
      type: Number,
      default: 76,
    },
    labelText: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      validColor: '#759F01',
      invalidColor: 'red',
    };
  },

  computed: {
    color() {
      return this.invalid ? this.invalidColor : this.validColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  position: absolute;
  padding: 10px;
}

.label-inner {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.label-text {
  color: #FFFFFF;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
}
</style>
