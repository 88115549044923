<template>
  <div class="cash-desk">
    <order-top-notices :order="order" />

    <cash-desk-header
      :order="order"
      :technical-window-open="technicalWindowOpen"
      @toggleTechnicalWindow="toggleTechnicalWindow"
    />

    <technical-settings
      v-if="technicalWindowOpen"
      :cash-desk-id="cashDeskId"
    />

    <order-trays
      v-if="ordersByRaspberry[raspberryId] && !technicalWindowOpen"
      :order="order"
    />

    <div class="total">
      <div>
        {{ $t('global.trays') + ': ' + (trays?.length || 0) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import OrderTrays from './OrderTrays.vue';
import OrderTopNotices from './OrderTopNotices.vue';
import CashDeskHeader from './CashDeskHeader.vue';
import TechnicalSettings from './TechnicalSettings/TechnicalSettings.vue';
import { CASH_DESK_STATES } from '@/constants';
import { VuexTypes } from '@/store/types';

export default {
  name: 'CashDeskView',

  components: {
    OrderTrays,
    OrderTopNotices,
    CashDeskHeader,
    TechnicalSettings,
  },

  provide() {
    return {
      _cashDeskId: this.cashDeskId,
    };
  },

  data() {
    return {
      raspberryId: null,
      cashDeskId: null,
      technicalWindowOpen: false,
    };
  },

  computed: {
    ...mapState('cashierOrders', {
      ordersByRaspberry: 'byRaspberry',
    }),
    ...mapState('cashierTrays', {
      traysByOrder: 'byOrder',
    }),
    ...mapGetters('cashierTrays', {
      getTrayTotal: 'trayTotal',
      getOrderTotal: 'orderTotal',
    }),
    ...mapState('cashierRaspberries', {
      raspberries: 'list',
    }),
    order() {
      return this.ordersByRaspberry[this.raspberryId];
    },
    orderState() {
      return this.ordersByRaspberry[this.raspberryId]?.state;
    },
    trays() {
      if (!this.order) {
        return {};
      }

      return this.traysByOrder[this.order?.id];
    },
  },

  watch: {
    '$route.params.raspberry_id': {
      handler: function (raspberryId) {
        if (!raspberryId || !this.raspberries.find(({ id }) => id == raspberryId)) {
          this.$router.push('/cashier');
        }

        this.raspberryId = isNaN(parseInt(raspberryId)) ? null : parseInt(raspberryId);
        this.setCurrentRaspberry(this.raspberryId);

        this.cashDeskId = this.raspberries.find(({ id }) => id == raspberryId)?.cash_desk_id;
      },
      deep: true,
      immediate: true,
    },
    orderState: {
      handler: function (state) {
        if (state === CASH_DESK_STATES.IN_PROGRESS) {
          this.technicalWindowOpen = false;
        }
      },
    },
  },

  mounted() {
    if (!this.order) {
      this.$router.push('/cashier').catch(() => {
      });
    }
  },

  methods: {
    ...mapActions('cashierRaspberries', {
      setCurrentRaspberry: VuexTypes.CASHIER_SET_CURRENT_RASPBERRY,
    }),
    toggleTechnicalWindow() {
      this.technicalWindowOpen = !this.technicalWindowOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.cash-desk {
  padding: 1.875rem 1.5rem 2rem;
  margin-right: 20px;
  overflow: hidden;
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #000000;
  font-weight: 800;

  > div:first {
    font-size: 18px;
    line-height: 25px;
  }

  > div:nth-child(2) {
    font-size: 30px;
    line-height: 41px;
  }
}
</style>
