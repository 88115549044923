
import { i18n } from '@/_translate';

/**
 * Returns the human product to use as the "real" human product
 * @param {Object} trayProduct 
 * @returns {Object}
 */
export function getHumanProduct(trayProduct) {
  return trayProduct.human_product_corrected || trayProduct.human_product;
}

/**
 * Returns the product name:
 *  1. attempt to retrieve the name from `translations` according to 'lang'
 *  2. attempt to retrieve the name from `translations` according to 'default_lang'
 *  3. retrieve the name from `name`
 * @param {Object} product - the product
 * @param {String} default_lang - fallback translation language
 * @returns {String} the product name
 */
export function getProductName(product, default_lang = 'en') {
  return product?.translations?.name?.[i18n.locale.toLocaleUpperCase()] ||
    product?.translations?.name?.[default_lang.toLocaleUpperCase()] ||
    product?.name;
}

/**
 * Returns the category name:
 *  1. attempt to retrieve the name from `translations` according to 'lang'
 *  2. attempt to retrieve the name from `translations` according to 'default_lang'
 *  3. retrieve the name from `name`
 * @param {Object} category - the category
 * @param {String} default_lang - fallback translation language
 * @returns {String} the category name
 */
export function getCategoryName(category, default_lang = 'en') {
  return category?.translations?.name?.[i18n.locale.toLocaleUpperCase()] ||
    category?.translations?.name?.[default_lang.toLocaleUpperCase()] ||
    category?.name;
}

export default {
  getHumanProduct,
  getCategoryName,
  getProductName,
};