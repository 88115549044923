<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('raspberry_emulator.header') }}
          </div>

          <div class="card-block">
            <b-form>
              <b-form-group>
                <b-list-group>
                  <b-list-group-item>
                    Select Raspberry
                    <RaspberrySelect v-model="selectedRaspberry" />
                  </b-list-group-item>

                  <template v-if="selectedRaspberry">
                    humanProducts
                    <b-list-group-item>
                      token: <b-form-input
                        v-model="token"
                        type="text"
                      />
                    </b-list-group-item>
                  </template>
                </b-list-group>

                <b-button @click.prevent="authCashDesk">
                  HttpAuth
                </b-button>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="token">
      <b-col sm="12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> Send Img
          </div>

          <div class="card-block">
            <b-form>
              <b-form-group label="Image">
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  @change="onFileChanged"
                >
                {{ selectedFile }}
              </b-form-group>

              <b-button @click.prevent="sendStreamFrame">
                Send stream frame
              </b-button>

              <b-button @click.prevent="sendImg">
                To inference-server
              </b-button>

              <b-button @click.prevent="createTray">
                Manualy create tray
              </b-button>

              <EmulatorAddProduct
                :cdid="CDID"
                :current-tray-id="currentTrayId"
                :token="token"
              />
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthService from '@/services/auth-service';
import axios from 'axios';
import RaspberrySelect from './RaspberrySelect.vue';
import EmulatorAddProduct from './EmulatorAddProduct.vue';
import { encode } from '@msgpack/msgpack';
import { mapState } from 'vuex';

export default {
  name: 'RaspberryEmulator',

  components: {
    RaspberrySelect, EmulatorAddProduct,
  },

  data() {
    return {
      selectedRaspberry: null,
      CDID: localStorage.getItem('CDID') || '',
      jwtPk: localStorage.getItem('jwk-pk') || '',
      token: null,
      selectedFile: null,
      preview: {
        enabled: false,
        imageSrc: '',
        delay: 1000,
        promise: 0,
      },
      currentTrayId: null,
    };
  },

  computed: {
    ...mapState(['auth']),
  },

  methods: {
    async authCashDesk() {
      const customClaims = {
        iss: 'api',
        HDID: this.selectedRaspberry.hardware_id,
        iat: moment().unix(),
        nbf: moment().unix(),
        exp: moment().unix() + 180,
        sub: 0,
        jti: '',
      };

      const signToken = await this.createJwt(customClaims, this.selectedRaspberry.jwt_pk);

      AuthService.cacheDeskLogin(signToken).then((response) => {
        this.token = response.data.token;
        this.CDID = response.data.id;
      }).catch((error) => {
        console.error(error);
      });
    },
    changeDelay(value) {
      const oldDelay = this.preview.delay;
      const newDelay = oldDelay + value;
      if (newDelay > 100) {
        this.preview.delay = newDelay;
      } else {
        this.preview.delay = 100;
      }
    },
    async sendStreamFrame() {
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          let encoded = encode({
            Frame: [
              {
                unix_ts_millis: Date.now(),
              },
              new Uint8Array(reader.result),
            ],
          });
          await axios.post(
            `/stream/send_debug_msg/${this.selectedRaspberry.id}/`,
            encoded,
            {
              headers: {
                Authorization: `Bearer ${this.auth.token}`,
              },
            },
          );

          this.$notify({
            type: 'success',
            title: this.$t('global.success'),
            text: this.$t('raspberry_emulator.success_on_send_frame'),
            duration: 1000,
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${this.$t('raspberry_emulator.error_on_send_frame')}: ${error}`,
            duration: -1,
          });
        }
      };

      reader.readAsArrayBuffer(this.selectedFile);
    },
    sendImg() {
      const reader = new FileReader();

      // Send the binary file to the server.
      reader.onloadend = async () => {
        try {
          let encoded = encode({
            RunInference: {
              ProvidedFrame: [
                {
                  unix_ts_millis: Date.now(),
                },
                new Uint8Array(reader.result),
              ],
            },
          });
          await axios.post(
            `/stream/send_debug_msg/${this.selectedRaspberry.id}/`,
            encoded,
            {
              headers: {
                Authorization: `Bearer ${this.auth.token}`,
              },
            },
          );

          this.$notify({
            type: 'success',
            title: this.$t('global.success'),
            text: this.$t('raspberry_emulator.success_on_send'),
            duration: 5000,
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${this.$t('raspberry_emulator.error_on_send')}: ${error}`,
            duration: -1,
          });
        }
      };

      reader.readAsArrayBuffer(this.selectedFile);
    },
    createTray() {
      const reader = new FileReader();

      // Send the binary file to the server.
      reader.onloadend = async () => {
        try {
          const base64Img = Buffer.from(reader.result, 'binary').toString('base64');

          await axios({
            url: '/api/image/tray',
            method: 'post',
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'multipart/form-data',
            },
            data: {
              image: `data:image/${this.selectedFile.type};base64,${base64Img}`,
              CDID: this.CDID,
              'workflow': 'training',
            },
          }).then(({ data: { trayId} }) => {
            this.currentTrayId = trayId;
            axios({
              url: `/api/tray_processed/${trayId}`,
              method: 'post',
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
              data: {},
            }).then(() => {
              this.$notify({
                type: 'success',
                title: this.$t('global.success'),
                text: this.$t('raspberry_emulator.success_on_send'),
                duration: 5000,
              });
            });
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${this.$t('raspberry_emulator.error_on_send')}: ${error}`,
            duration: -1,
          });
        }
      };

      reader.readAsArrayBuffer(this.selectedFile);
    },
    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      });
      xhr.open('GET', url);
      xhr.setRequestHeader('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9');
      xhr.responseType = 'blob';
      xhr.send();
    },
    async createJwt(claims, secret) {
      const jose = require('jose');

      const secret2 = new TextEncoder().encode(secret);
      const jwt = await new jose.SignJWT(claims)
        .setProtectedHeader({ alg: 'HS256' })
        .sign(secret2);

      return jwt;
    },
  },
};
</script>
