<template>
  <div class="order-top">
    <div class="titles">
      <img src="@/static/img/plate.svg" />

      <div class="titles-text">
        <p class="title">
          <template v-if="order.state === CASH_DESK_STATES.PAID">
            {{ $t('global.order.paid') }}
          </template>

          <template v-else-if="order.state === CASH_DESK_STATES.VOIDED">
            {{ $t('global.order.voided') }}
          </template>

          <template v-else>
            {{ $t('global.occupied') }}
          </template>
        </p>

        <p
          v-if="isInProgress"
          class="subtitle"
        >
          {{ $t('global.order_in_progress') }}
        </p>
      </div>
    </div>

    <div class="order-controls">
      <!-- LOCK -->
      <button
        class="btn-lock"
        :disabled="!canLock"
        @click="lock"
      >
        <img
          class="btn-lock-img"
          src="@/static/img/lock.svg"
        />

        <p>
          {{ $t('cashier.cashDesk.btn.lock') }}
        </p>
      </button>

      <!-- AGE VERIFY -->
      <button
        v-if="canVerifyAge"
        class="btn-18"
        :class="{ 'success': order.age_verified, 'primary': !order.age_verified }"
        :disabled="order.age_verified === 1"
        @click="orderVerifyAge(order)"
      >
        <img
          v-if="!order.age_verified"
          src="@/static/img/18-blue.svg"
        />

        <img
          v-else
          src="@/static/img/18-white.svg"
        />
      </button>

      <!-- DOTS DROPDOWN -->
      <b-dropdown
        v-if="!canLock"
        variant="link"
        class="dot-dropdown"
        right
        no-caret
      >
        <template #button-content>
          <img src="@/static/img/3-dots.svg" />
        </template>

        <b-dropdown-item
          v-if="canVoidOrder"
          @click="toggleTechnicalWindowOpen"
        >
          <div class="cashier-dropdown">
            <img
              src="@/static/img/tools-blue.svg"
            />
            {{ getTranslation }}
          </div>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="canVoidOrder"
          @click="voidOrder"
        >
          <div class="cashier-dropdown">
            <img
              src="@/static/img/terminal-lock.svg"
            />
            {{ $t('cashier.cashDesk.voidOrder') }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- MODALS -->
    <modal-confirm-lock
      v-model="modals.confirmLock"
      :order="order"
    />

    <modal-confirm-void-order
      v-model="modals.confirmVoidOrder"
      :order="order"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { canLockOrder, canVerifyAgeOrder, canVoidOrder } from '@/rules/order-rules';
import { VuexTypes } from '@/store/types';
import ModalConfirmLock from './Modals/ModalConfirmLock.vue';
import ModalConfirmVoidOrder from './Modals/ModalConfirmVoidOrder.vue';
import { CASH_DESK_STATES } from '@/constants';

export default {
  name: 'CashDeskHeader',

  components: {
    ModalConfirmLock,
    ModalConfirmVoidOrder,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    technicalWindowOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      modals: {
        confirmLock: false,
        confirmVoidOrder: false,
      },
    };
  },

  computed: {
    getTranslation() {
      return !this.value ? this.$dt('cashier.cashDesk.technicalMenu') : this.$dt('cashier.cashDesk.closeTechnicalMenu');
    },
    canLock() {
      return canLockOrder(this.order);
    },
    canVerifyAge() {
      return canVerifyAgeOrder(this.order);
    },
    canVoidOrder() {
      return canVoidOrder(this.order);
    },
    isInProgress() {
      return ![CASH_DESK_STATES.PAID, CASH_DESK_STATES.VOIDED].includes(this.order.state);
    },
  },

  created() {
    this.CASH_DESK_STATES = CASH_DESK_STATES;
  },

  methods: {
    ...mapActions('cashierOrders', {
      lockOrder: VuexTypes.CASHIER_ORDERS_ACTION_LOCK,
      orderVerifyAge: VuexTypes.CASHIER_ORDERS_ACTION_VERIFY_AGE,
    }),
    lock() {
      this.modals.confirmLock = true;
    },
    voidOrder() {
      this.modals.confirmVoidOrder = true;
    },
    toggleTechnicalWindowOpen() {
      this.$emit('toggleTechnicalWindow');
    },
  },
};
</script>

<style lang="scss" scoped>
.order-top {
  display: flex;
  justify-content: space-between;

  .titles {
    display: flex;

    img {
      align-self: flex-start;
      width: 3.125rem;
    }

    p {
      font-weight: 800;
      margin-left: 1.875rem;
    }

    .title {
      font-weight: 800;
      font-size: 1.875rem;
      line-height: 2.5rem;
      text-transform: uppercase;
    }

    .subtitle {
      font-weight: 800;
      text-transform: uppercase;
      font-size: 1.25rem;
      line-height: 1.7rem;
    }
  }

  .order-controls {
    display: flex;

    .btn-lock {
      display: flex;
      padding: 0.9rem 1rem;
      gap: 0.6rem;
      width: 11.5rem;
      height: 3.4rem;
      color: #FFFFFF;
      border: none;
      background: #003C7E;
      border-radius: 8.75rem;

      p {
        margin-top: 0.125rem;
        height: 100%;
        font-weight: 800;
        font-size: 0.875rem;
        line-height: 1.4rem;
        text-align: center;
        text-transform: uppercase;
        overflow: hidden;
      }

      &:disabled {
        background: #DEDEDE;
      }
    }

    &.btn-18 {
      border: 2px solid #003C7E;
      padding: 15px 14px;
    }

    .btn-18 {
      box-shadow: none;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      padding: 0.938rem;
      gap: 0.625rem;
      width: 3.375rem;
      height: 3.375rem;
      border: 0.125rem solid #003C7E;
      border-radius: 8.75rem;
    }

    .btn-18.primary {
      border: 0.125rem solid #003C7E;
    }

    .btn-18.success {
      background-color: #759F01;
      border: 0.125rem solid #759F01;
    }

    .cashier-dropdown {
      color: #003C7E;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;

      img {
        width: 1.5rem;
      }
    }

    img {
      margin-right: 10px;
    }
  }

  .dot-dropdown {
    height: 3.375rem;
  }
}
</style>
